import sample from "lodash/sample";

export const CHAIN_ID = 56;
export const NODES = {
  // 56: [
  //   "https://virtual.binance.rpc.tenderly.co/bee34fda-2dd3-4784-bd00-ea4fd644e4e0",
  // ],
  56: ["https://binance.llamarpc.com"],
};

const getNodeUrl = () => {
  const nodeUrl = sample(NODES[CHAIN_ID]);
  if (nodeUrl === undefined) {
    return NODES[CHAIN_ID][0];
  }
  return nodeUrl;
};

export default getNodeUrl;
